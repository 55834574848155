<template>
   <v-container fluid>
    <TitleBar :title="'แบบสำรวจแบบฟอร์ม'" :subtitle="items.length + ' แบบฟอร์ม'">
      <template slot="action">
          <v-btn x-large color="primary" @click="openCreateFromDialog()"><v-icon>mdi-plus</v-icon><span class="text-title-2">สร้างแบบฟอร์ม</span></v-btn>
      </template>
    </TitleBar>
      <FilterBar>
      <template v-slot:left>
         <v-col cols="12" md="4" >
          <TextField v-model="search" :title="'ค้นหา'" append-icon="mdi-magnify" placeholder="ชื่อ,ผู้สร้าง,อื่นๆ"/>
         </v-col>
      </template>
      <template v-slot:right>
        <v-col cols="12" md="4" >
                <Select :title="'ประเภท'" multiple :placeholder="'ทั้งหมด'" :items="formTypeItems" v-model="formType" item-text="formTypeName" item-value="formTypeId">
                  <template v-slot:append-item>
        <v-list-item
          ripple
          @click="toggle"
        >
          <v-list-item-action>
            <v-icon :color="formType.length > 0 ? 'indigo darken-4' : ''">
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              ทั้งหมด
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
                </Select>
              </v-col>
               <v-col cols="12" md="2" >
                 <Select :title="'สถานะ'" :placeholder="'โปรดเลือก'" :items="statusItems" v-model="status" item-text="text" item-value="value"/>
              </v-col>
               <v-col cols="12" md="2" >
                 <Select :title="'ประจำปี'" multiple :placeholder="'โปรดเลือก'" :items="yearItems" v-model="year" item-text="yearTH" item-value="yearEN"/>
              </v-col>
      </template>
    </FilterBar>
    <DataTable
    :headers="headers"
    :loading="dataLoading"
    :search="search"
    :items="items"
    :footer-props="{
    'items-per-page-options': [10, 50, 100]
  }"
    >
       <template v-slot:item.action="{item}">
         <v-btn icon @click="editForm(item)">
            <v-icon>mdi-pencil</v-icon>
         </v-btn>
       </template>
       <template v-slot:item.createDate="{item}">
         {{$dayjs(new Date(item.createDate) ).locale('th').format('DD MMMM BBBB HH:mm')}}
       </template>
          <template v-slot:item.active="{item}">
       <li style="list-style-type: circle;width: -1em;" class="success--text" v-if="item.active"><span class="success--text">พร้อมใช้</span></li>
       <li style="list-style-type: circle;width: -1em;" class="error--text" v-else><span class="error--text">ระงับใช้</span></li>
       </template>
       <template v-slot:item.year="{item}">
       {{Number(item.year) + 543}}
       </template>
       <template v-slot:item.countResponse="{item}">
         <v-icon small color="natural">mdi-comment-text-multiple-outline</v-icon>
         <a @click="viewResult(item)">&nbsp;{{item.countResponse}}</a>
       </template>
    </DataTable>
     <v-dialog v-model="createFormDialog" width="450" persistent>
      <CreateFormCard @onClose="createFormDialog=false" @onSubmit="createForm" :action="'สร้างฟอร์ม'" :status="createFormDialog" :yearItems="yearItems" :formTypeItems="formTypeItems" v-model="form" />
    </v-dialog>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
       <v-snackbar
        v-model="postStatus.code"
        color="natural darken-1"
      >
      <v-avatar size="35">
      <v-img width="35" :src="require('@/assets/circlesuccess.svg')"/>
      </v-avatar>
        {{postStatus.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
            icon
            v-bind="attrs"
            @click="postStatus.code = false"
          >
            <v-icon>
              mdi-close
           </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
</template>

<script>
import TitleBar from '@/components/common/TitleBar'
import TextField from '@/components/common/TextField'
import FilterBar from '@/components/common/FilterBar'
import DataTable from '@/components/common/DataTable'
import Select from '@/components/common/Select'
import CreateFormCard from '@/components/form/CreateFormCard'
import { getForm, getYear, getSiteFormType } from '@/api/' // getForm
// import advancedFormat from 'dayjs/plugin/buddhistEra'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    TitleBar,
    TextField,
    FilterBar,
    DataTable,
    Select,
    CreateFormCard
  },
  data () {
    return {
      items: [],
      headers: [
        { text: 'ชื่อ', value: 'name', class: 'primary lighten-5' },
        { text: 'ประเภท', value: 'formTypeName', class: 'primary lighten-5' },
        { text: 'ประจำปี', value: 'year', class: 'primary lighten-5', width: '100px' },
        { text: 'วันที่สร้าง', value: 'createDate', class: 'primary lighten-5' },
        { text: 'ผู้สร้าง', value: 'createByUserName', class: 'primary lighten-5', width: '200px' },
        { text: 'จำนวนคนตอบ', value: 'countResponse', class: 'primary lighten-5', width: '140px', align: 'left' },
        { text: 'สถานะ', value: 'active', class: 'primary lighten-5', width: '150px' },
        { text: 'Action', value: 'action', class: 'primary lighten-5', width: '100px' }
      ],
      loading: false,
      snackbar: true,
      dataLoading: false,
      search: null,
      createFormDialog: false,
      year: null,
      yearItems: [],
      statusItems: [
        { text: 'ทั้งหมด', value: [true, false] },
        { text: 'พร้อมใช้', value: [true] },
        { text: 'ระงับใช้', value: [false] }
      ],
      status: [true],
      formTypeItems: [],
      formType: [],
      formObject: {}
    }
  },
  watch: {
    year () {
      this.getForm()
    },
    status () {
      this.getForm()
    },
    formType () {
      this.getForm()
    }
  },
  methods: {
    openCreateFromDialog () {
      this.form = {}
      this.createFormDialog = true
    },
    createForm () {
      this.createFormDialog = false
      this.$router.push({ name: 'formcreate' })
    },
    editForm (item) {
      this.form = item
      this.$router.push({ name: 'formedit' })
    },
    buildParam (param, data) {
      let ret = ''
      for (let i = 0; i < data.length; i++) {
        if (i < 1) {
          ret += param + '=' + data[i]
        } else {
          ret += '&' + param + '=' + data[i]
        }
      }
      return ret
    },
    buildArrayItem (key, data) {
      const ret = []
      for (let i = 0; i < data.length; i++) {
        ret.push(data[i][key])
      }
      return ret
    },
    toggle () {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.formType = []
        } else {
          this.formType = this.buildArrayItem('formTypeId', this.formTypeItems)
        }
      })
    },
    viewResult (item) {
      this.result = item
      this.$router.push({ name: 'formresult' })
    },
    getForm () {
    // Query Datatable data
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      this.dataLoading = true
      getForm({
        tenantId: tenant.tenantId,
        siteId: site.siteId,
        offset: 0,
        limit: 100,
        formTypeId: (Array.isArray(this.formType)) ? this.buildParam('formTypeId', this.formType) : this.buildParam('formTypeId', [this.formType]),
        // year: '[' + this.year.join(',') + ']',
        year: this.buildParam('year', this.year),
        active: this.buildParam('active', this.status)// this.status
      }, message => {
        if (message.data.code === 1) {
          if (message.data.result) {
            this.items = message.data.result.items
          } else {
            this.items = []
          }
        }
        this.dataLoading = false
      }, error => {
        this.dataLoading = false
        console.log(error)
      })
    },
    async fetchData () {
      // Clear state
      this.result = {}
      this.loading = true
      if (this.$auth.role) {
        // Query Filter Data
        try {
          let resp = await getYear()
          if (resp.data.code === 1) {
            this.yearItems = resp.data.result
            this.year = [String(new Date().getFullYear())]
          }
          const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
          const site = JSON.parse(sessionStorage.getItem('Site'))
          resp = await getSiteFormType({
            tenantId: tenant.tenantId,
            siteId: site.siteId,
            offset: 0,
            limit: 100,
            sortBy: 'formTypeName',
            sorDesc: 0
          })
          if (resp.data.code === 1) {
            this.formTypeItems = resp.data.result.items
            // this.formTypeItems.push({ formTypeId: this.buildArrayItem('formTypeId', resp.data.result.items), formTypeName: 'ทั้งหมด' })
            this.formType = []// this.buildArrayItem('formTypeId', resp.data.result.items)
          }
        } catch (error) {
          console.log(error)
        } finally {
          this.loading = false
        }
        this.getForm()
      }
    }
  },
  computed: {
    ...mapFields(['form', 'result', 'postStatus']),
    icon () {
      if (this.likesAllFruit) return 'mdi-close-box'
      if (this.likesSomeFruit) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    likesAllFormType () {
      return this.formType.length === this.formTypeItems.length
    },
    likesSomeFromType () {
      return this.formType.length > 0 && !this.likesAllFormType
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }

}
</script>

<style>

</style>
